import React, { useState, useEffect } from 'react';
import CryptoJS from "crypto-js";
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import service, { DMS, DMS_URL } from '../../../services/service';
import UserService from '../../../services/UserService';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie';
import ContentDeliveryNav from './Navbar/ContentDeliveryNav';
import ContentDeliverySidebar from './Sidebar/ContentDeliverySidebar';
import ContentDeliveryArea from './ContentArea/ContentDeliveryArea';
import "./ContentDeliveryMain.css";
import { Circles } from 'react-loader-spinner';
import axios from 'axios';
// import ContentDeliveryRatingSideBar from './RatingSidebar/ContentDeliveryRatingSideBar';
// import ContentDeliveryContentNotes from './ContentNotes/ContentDeliveryContentNotes';
// import ContentDeliveryToolbar from './ContentDeliveryToolbar/ContentDeliveryToolbar';


const languages = [

    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },

    {
        code: 'hi',
        name: 'Hindi',
        country_code: 'in'
    },
    {
        code: 'pu',
        name: 'Punjabi',
        country_code: 'in'
    }
]

const ContentDeliveryUpdated = (props) => {
    const history = useHistory();
    const [courseId, setDecodedCourseId] = useState(null);
    const [tenantId, setDecodedTenantId] = useState(null);
    const [visibility, setVisibility] = useState(true);
    const [roleId, setRoleId] = useState("");

    const [mergerLoader, setMergerLoader] = useState(false);
    const [courseActivitiesLoader, setCourseActivitiesLoader] = useState(false);
    const [courseStructureLoader, setCourseStructureLoader] = useState(false);

    const [contentLoader, setContentLoader] = useState(false);

    const [isDecodeValid, setIsDecodeValid] = useState("NOTVALID");
    let userId = UserService.getUserid();
    let sessionId = UserService.getSessionId();
    useEffect(() => {
        UserService.generateToken();
    }, []);

    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    const [contentId, setContentId] = useState(null);

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr'
        document.title = t('app_title')
    }, [currentLanguage, t])

    useEffect(() => {
        if (isDecodeValid === "VALID") {

            if (userId) {
                getEnrollmentStatus();
            }
            if (UserService.isLoggedIn()) {
                getbyCourseIdandUserId();
            }

        }

    }, [isDecodeValid, courseId])

    const getEnrollmentStatus = () => {
        service.getUserEnrollmentStatus(courseId, userId, 1, tenantId)
            .then(res => {
                if (res.data.courseEnrolled === true) {
                    setVisibility(false);
                }
            })
            .catch(err => {

            })
    }
    const getbyCourseIdandUserId = () => {


        if (userId !== " ") {
            service.getRoleIdbyCourseIdandUserId(courseId, userId)
                .then((res) => {
                    if (res.status === 200) {
                        setRoleId(res.data);
                    }
                    else {
                    }
                })
                .catch((err) => {
                })
        }
    }

    const decodeHandler = () => {

        const secretKey = "cdac@123"

        const encodedCourseId = props.match.params.id.replace(/-/g, "+").replace(/_/g, "/");
        const decodedCourseId = CryptoJS.AES.decrypt(
            encodedCourseId,
            secretKey
        ).toString(CryptoJS.enc.Utf8);

        const encodedTenantId = props.match.params.tid.replace(/-/g, "+").replace(/_/g, "/");
        const decodedTenantId = CryptoJS.AES.decrypt(
            encodedTenantId,
            secretKey
        ).toString(CryptoJS.enc.Utf8);

        const regex = /^[0-9]+$/

        const isValidCourseId = regex.test(decodedCourseId)
        // const isValidTenantId = regex.test(decodedTenantId)

        if (isValidCourseId) {
            setIsDecodeValid("VALID")
            setDecodedCourseId(decodedCourseId)
            setDecodedTenantId(decodedTenantId)
        }
        else {
            setIsDecodeValid("NOTVALID")

            swal({
                title: t('something_went_wrong_try_later'),
                text: t('redirecting_to_course_list'),
                timer: 5000,
            }).then(() => {
                // history.push(`${process.env.PUBLIC_URL + "/course-grid/"}`);
            })
        }

    }

    useEffect(() => {
        decodeHandler();
    }, [isDecodeValid])

    const CourseDetailsRedirect = () => {

        updateContentOutTime(selectedContent)

        let id = courseId;
        let tid = tenantId;

        const secretKey = "cdac@123"

        const encodedCourseId = CryptoJS.AES.encrypt(
            `${id}`,
            secretKey
        ).toString();
        const safeEncodedCourseId = encodedCourseId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        const encodedTenantId = CryptoJS.AES.encrypt(
            `${tid}`,
            secretKey
        ).toString();
        const safeEncodedTenantId = encodedTenantId
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "");

        history.push(`${process.env.PUBLIC_URL + "/course-details/"}${safeEncodedCourseId}/${safeEncodedTenantId}`);
        // setTimeout(() => {
        //     history.push(`${process.env.PUBLIC_URL}/course-details/${safeEncodedCourseId}/${safeEncodedTenantId}`);
        // }, 5000);
    }

    //////////////////////////////////////////////////////////////////

    const [allActivitiesStatus, setAllActivitiesStatus] = useState({});
    const [courseStructureData, setCourseStructureData] = useState({});
    const [mergedCourseStructure, setMergedCourseStructure] = useState({});

    const [sideBarOpen, setSideBarOpen] = useState(true);


    const [selectedContent, setSelectedContent] = useState({});
    const [previousContent, setPreviousContent] = useState({});
    const [updateData, setUpdateData] = useState(false);

    const [duration, setDuration] = useState(0);
    const [updatedSelectedContentData, setUpdatedSelectedContentData] = useState({});
    const [intervalDuration, setIntervalDuration] = useState();
    const [url, setUrl] = useState(null);
    const [activityProgress, setActivityProgress] = useState(0);
    const [showContent, setShowContent] = useState(false);
    const [videoEncodeStatus, setVideoEncodeStatus] = useState(null);

    const [ratingSideBarOpen, setRatingSideBarOpen] = useState(false);
    const [notesSideBarOpen, setNotesSideBarOpen] = useState(false);
    const [toolBarOpen, setToolBarOpen] = useState(false);

    const toggleRatingSidebar = () => {
        setRatingSideBarOpen(!ratingSideBarOpen);
        setToolBarOpen(false);
        setNotesSideBarOpen(false);
    }

    const toggleNotesSidebar = () => {
        setNotesSideBarOpen(!notesSideBarOpen);
        setToolBarOpen(false);
        setRatingSideBarOpen(false);
    }

    const toggleToolBar = () => {
        setToolBarOpen(!toolBarOpen)
    }

    const toggleUpdateData = () => {
        setUpdateData(!updateData);
    }

    const fetchActivitiesStatus = () => {
        setCourseActivitiesLoader(true);
        if (isDecodeValid === "VALID") {
            service.courseActivitiesStatus(userId, courseId)
                .then(res => {
                    setAllActivitiesStatus(res.data);
                    setCourseActivitiesLoader(false);
                })
                .catch(err => {
                    // Handle errors
                    console.error(err);
                    setCourseActivitiesLoader(false);
                });
        }
    };

    const fetchCourseStructure = () => {
        setCourseStructureLoader(true);
        if (isDecodeValid === "VALID") {
            service.getPublishedCourseStructure(courseId, userId)
                .then(res => {
                    setCourseStructureData(res.data);
                    setCourseStructureLoader(false);
                })
                .catch(err => {
                    // Handle errors
                    console.error(err);
                    setCourseStructureLoader(false);
                });
        }
    };

    useEffect(() => {
        fetchActivitiesStatus();
        fetchCourseStructure();
    }, [courseId, updateData])

    useEffect(() => {
        if (courseStructureData != null && allActivitiesStatus != null) {
            mergeData();
        }
    }, [courseStructureData, allActivitiesStatus])

    const mergeData = () => {
        setMergerLoader(true);
        if (!courseStructureData || !allActivitiesStatus) {
            return; // Handle if data is not available
        }

        let mergedData = { ...courseStructureData }; // Create a copy to avoid modifying the original data

        if (!mergedData.nodes || !Array.isArray(mergedData.nodes)) {
            // console.error("Nodes are not available or not in the expected format.");
            return;
        }

        mergedData.nodes.forEach(module => {
            if (module.nodes && Array.isArray(module.nodes)) {
                module.nodes.forEach(item => {
                    const activityId = item.id.toString(); // Convert to string as activityId is string in getActivitiesStatusData
                    const activityStatus = allActivitiesStatus[activityId];
                    if (activityStatus) {
                        // Merge activityStatus properties directly into item
                        Object.assign(item, activityStatus);
                    }
                });
            }
        });
        setMergerLoader(false);
        setMergedCourseStructure(mergedData);
    };

    const onToggleSideBar = () => {
        setSideBarOpen(!sideBarOpen);
    }

    const minutesToMilliseconds = (minutes) => {
        return minutes * 60 * 1000;
    };

    const onSelectContent = (selectedContent) => {
        setContentLoader(true);
        setShowContent(false);
        //console.log("selectedContent: ", selectedContent);
        if (Object.keys(selectedContent).length !== 0) {
            if (selectedContent.activityProgressforDependency !== 100) {
                swal("", "Please Complete the Previous Contents", "warning");
                return;
            } else {
                toggleUpdateData();
                //console.log("saveContentVisit");
                service.saveContentVisit(userId, courseId, selectedContent.id, selectedContent.label, sessionId, selectedContent.nodetype)
            }
        }

        setActivityProgress(selectedContent?.activityProgress);
        setDuration(selectedContent?.duration);
        setIntervalDuration(minutesToMilliseconds(selectedContent?.duration) / 4);

        if (selectedContent?.nodetype === "youtube" || selectedContent?.nodetype === "externalLink") {
            setUrl(selectedContent?.filePath);
            setContentLoader(false);
            setShowContent(true);
        }
        else if (selectedContent?.nodetype === "practiceQuiz") {
            setUrl("assessment/delivery/dashboard/conductor/quiz/ready/" + selectedContent?.filePath+"/nfsu");
            setShowContent(true);
            setContentLoader(false);
        }
        else if (selectedContent?.nodetype === "VLabs") {
            if (selectedContent?.DependencyActivityProgress === 100 && mergedCourseStructure?.label != "") {
                setContentLoader(false);
                service.VLABLink(selectedContent?.filePath, courseId, mergedCourseStructure?.label, sessionId)
                    .then(response => {
                        setShowContent(true);
                        let vlab_url = response.data;

                        let new_vlab_url = vlab_url.slice(1, -1);
                        window.open(response.data, '_blank');

                    })
                    .catch(err => {
                        ////console.log(err);
                    })
            }
        }
        else if (selectedContent?.nodetype === "pdf") {
            axios
                .get(DMS_URL + `${selectedContent?.filePath}`)
                .then((response) => {
                    setContentLoader(false);
                    setShowContent(true);
                    const encrypted = CryptoJS.AES.encrypt(
                        "/" + response.data,
                        "cdac@123"
                    ).toString();
                    const safeEncodedString = encrypted.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

                    const pdfDomain = DMS
                    const finalSubDomain = pdfDomain + "/" + safeEncodedString
                    setUrl(finalSubDomain);

                })
                .catch((error) => { });
        }
        else if (selectedContent?.nodetype) {
            axios
                .get(DMS_URL + `${selectedContent?.filePath}`, {
                    onDownloadProgress: (progressEvent) => {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        //console.log(progressEvent.lengthComputable)
                        //console.log(percentCompleted);
                    }
                })
                .then((response) => {
                    setShowContent(true);
                    setContentLoader(false);
                    if (response.data.endsWith('.mp4')) {
                        setVideoEncodeStatus('pending');
                    } else {
                        setVideoEncodeStatus('success');
                    }
                    setUrl(response.data);
                })
                .catch((error) => { });
        }
    }

    const handleNodeClick = (node) => {
        //console.log("node: ", node);
        setPreviousContent(selectedContent);
        setSelectedContent(node);
        onSelectContent(node);

        if (window.innerWidth < 768) {
            setSideBarOpen(false);
        }
    };

    const updateContentOutTime = (content) => {
        //console.log("MAIN updateContentOutTime CALLED");
        //console.log("updateContentOutTime CONTENT: ", content);

        if (Object.keys(content).length !== 0 && content?.nodetype !== "VLabs" && content?.nodetype !== "practiceQuiz") {
            service.updateContentVisitOutTime(userId, sessionId, courseId, content.id)
                .then((res) => {
                    // setPreviousContent(selectedContent)
                    if (res.status == 200) {
                        if (content?.activityProgress !== 100 && Object.keys(content).length !== 0) {
                            getContentProgressByActivityId();
                        }
                    }
                })
                .catch((err) => {
                })
        }
    }

    let intervalId = null; // Variable to hold interval ID, initialize as null

    useEffect(() => {
        if (intervalDuration) {
            clearInterval(intervalId); // Clear previous interval
            intervalId = setInterval(() => {
                //console.log("setInterval selectedContent: ", selectedContent);
                updateContentOutTime(selectedContent);

            }, intervalDuration);
        }
        return () => clearInterval(intervalId); // Clean up on component unmount
    }, [intervalDuration, selectedContent]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            // console.log("Every 2min interval called");
            updateContentOutTime(selectedContent);
        }, 120000);

        return () => {
            clearInterval(intervalId);
        };

    }, [selectedContent]);

    useEffect(() => {
        //console.log("USE EFFECT CALLED");
        if (Object.keys(previousContent).length !== 0 && (previousContent.id !== selectedContent.id)) {
            //console.log("INSIDE IF OF USE EFFECT");
            updateContentOutTime(previousContent);
        }
    }, [previousContent])


    const getContentProgressByActivityId = () => {
        //console.log("getActivityProgressByActivityId CALLED");
        service.getActivityProgressByActivityId(courseId, userId, selectedContent?.activityId)
            .then((res) => {
                setUpdatedSelectedContentData(res.data);
                setActivityProgress(res.data.activityProgress);
                if (res.data.activityProgress === 100) {
                    toggleUpdateData();
                }
            })
            .catch((err) => {
                //console.log("Error: ", err);
            })
    }
    // console.log("courseActivitiesLoader: ", courseActivitiesLoader);
    return (
        <div
            id='content-delivery-main'
            className='content-delivery-main-page'
        >
            {
                (mergerLoader || courseStructureLoader || courseActivitiesLoader) && (
                    <div
                        style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Adjust the transparency as needed
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000, // Ensure it's above other elements
                        }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Circles height={40} width={40} color="#4fa94d" ariaLabel="circles-loading" />
                        </div>
                    </div>
                )
            }
            <ContentDeliveryNav
                className="content-delivery-nav"
                mergedCourseStructure={mergedCourseStructure}
                CourseDetailsRedirect={CourseDetailsRedirect}
                onToggleSideBar={onToggleSideBar}
                sideBarOpen={sideBarOpen}
            />
            <ContentDeliverySidebar
                className="content-delivery-sidebar"
                mergedCourseStructure={mergedCourseStructure}
                sideBarOpen={sideBarOpen}
                setSideBarOpen={setSideBarOpen}
                selectedContent={selectedContent}
                handleNodeClick={handleNodeClick}
            />
            <ContentDeliveryArea
                className="content-delivery-area"
                mergedCourseStructure={mergedCourseStructure}
                selectedContent={selectedContent}
                sideBarOpen={sideBarOpen}
                userId={userId}
                courseId={courseId}
                sessionId={sessionId}
                url={url}
                showContent={showContent}
                activityProgress={activityProgress}
                videoEncodeStatus={videoEncodeStatus}
                contentLoader={contentLoader}
            />

            {/* <ContentDeliveryRatingSideBar
                className="content-delivery-rating-sidebar"
                selectedContent={selectedContent}
                ratingSideBarOpen={ratingSideBarOpen}
                setRatingSideBarOpen={setRatingSideBarOpen}
                toggleRatingSidebar={toggleRatingSidebar}
            />

            <ContentDeliveryContentNotes
                className="content-delivery-notes-sidebar"
                selectedContent={selectedContent}
                courseId={courseId}
                notesSideBarOpen={notesSideBarOpen}
                setNotesSideBarOpen={setNotesSideBarOpen}
                toggleNotesSidebar={toggleNotesSidebar}
            />

            <ContentDeliveryToolbar
                className="content-delivery-toolbar"
                selectedContent={selectedContent}
                toggleRatingSidebar={toggleRatingSidebar}
                toggleNotesSidebar={toggleNotesSidebar}
                toggleToolBar={toggleToolBar}
                toolBarOpen={toolBarOpen}
                setToolBarOpen={setToolBarOpen}
            /> */}
        </div>
    )

}

export default ContentDeliveryUpdated