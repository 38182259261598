import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip, Container } from 'react-bootstrap'
import instructorService from '../../../services/instructorService';
import swal from 'sweetalert';
import "./UpdateCourseDuration.css";
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';


const UpdateCourseDuration = ({ courseId }) => {

  const history = useHistory();

  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const currentDate = formatDate(new Date());

  const [courseDuration, setCourseDuration] = useState(0);
  const [courseDurationDate, setCourseDurationDate] = useState(null)
  const [courseDurationData, setCourseDurationData] = useState([]);
  const [courseData, setCourseData] = useState({});

  const [errors, setErrors] = useState({});

  const onDurationChange = (duration) => {

    setCourseDuration(duration);
    setCourseDurationDate(formatDate(moment(formatDate(courseData?.closingDate)).add(duration, 'days')));
  }

  const onDurationBlur = () => {
    const errors = {};
    if (isNaN(courseDuration)) {
      errors.duration = "Duration must be a number";
    }
    setErrors(errors);
  }

  useEffect(() => {
    fetchCourseDurationData();
    fetchCourseData();
  }, [courseId])


  const fetchCourseDurationData = () => {
    instructorService.getCourseDuartionUpdateData(courseId)
      .then((res) => {
        if (res.status == 200) {
          setCourseDurationData(res.data);
        }
      })
      .catch((err) => {
        swal("Error", err, "error");
      })
  }

  const fetchCourseData = () => {
    instructorService.getCourseById(courseId)
      .then((res) => {
        setCourseData(res.data);
        setCourseDurationDate(formatDate(res.data?.closingDate));

      })
      .catch((err) => {
        swal("Error", "Server Error please try again later", "error")
      })
  }

  const convertTimestampToDate = (timestamp) => {
    return moment(timestamp).format('MMMM Do YYYY, h:mm:ss a');
  };

  const durationColumns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      wrap: true,
      width: '150px',
    },
    {
      name: "Duration(Days)",
      selector: row => row.duartion,
      wrap: true,
      sortable: true,
      width: '150px',
    },
    {
      name: "Updated On",
      selector: row => convertTimestampToDate(row.updatedOn),
      sortable: true,
      wrap: true,
      width: '250px',
      // right: true
    }
  ];

  const courseDurationDateChangeHandler = (value) => {
    // console.log("value: ", value);

    let currentEndDateTime = moment(formatDate(courseData?.closingDate))
    let newDateTime = moment(value)

    let diffDuration = newDateTime.diff(currentEndDateTime, 'days')
    setCourseDuration(diffDuration)

    // console.log("diffDuration: ", diffDuration);

    setCourseDurationDate(moment(value));
  }


  const courseDurationDateBlurHandler = () => {

  }

  const onSubmitButtonClick = () => {

    if (Object.keys(errors).length !== 0) {
      swal("Warning", "Please Check the duration again", "warning")
    } else {
      instructorService.updateCourseDuration(courseId, courseDuration)
        .then((res) => {
          if (res.status === 200) {
            swal("Success", "Successfully Updated the Schedules", "success")
            fetchCourseDurationData();
            fetchCourseData();
          } else {

          }
        })
        .catch((err) => {
          swal("Error", err, "error");
        })
    }
    setCourseDuration(0);
    setCourseDurationDate(null);
  }

  const handleGoBack = () => {
    history.push({
      pathname: '/ViewCourses',
      state: { showCourseEditModal: true, courseId }
    });
  };


  // console.log("courseDurationDate:", courseDurationDate);
  // console.log("courseDurationData: ", courseDurationData);
  // console.log("Errors: ", errors);
  // console.log("newEndDate: ", newEndDate);
  //console.log("courseDuration: ", courseDuration);



  return (
    <div className='main-course-duration-update-page'>
      <Row>
        <Col md={5}>
          <div className='course-duration-update-left'>
            <div className='input-area-duration'>
              <label className='label-duration'>Course Duration Extend(in Days):
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      Enter the Duration by which you want to extend the Course Duration (in Days)
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="number"
                className="courseDuration-form"
                value={courseDuration}
                onChange={(e) => onDurationChange(e.target.value)}
                onBlur={() => onDurationBlur()}
                placeholder={"Enter the Course Commencement Date"}
              />
              {errors?.duration && <span className="error-message">{errors?.duration}</span>}
              <div className='duration-date-separation'>OR</div>
              <input
                type="date"
                className="endDate"
                value={formatDate(courseDurationDate)}
                onChange={(e) => courseDurationDateChangeHandler(e.target.value)}
                onBlur={() => courseDurationDateBlurHandler()}
                placeholder={"Enter the Course Commencement Date"}
              />

            </div>
            <div className='submit-button-area'>
              <div className='d-flex justify-content-end'>
                <Button
                  size='lg'
                  className='submit-button mr-2 btn-success' // Add margin-right for spacing
                  onClick={onSubmitButtonClick}
                  disabled={courseDuration == 0}
                >
                  Submit
                </Button>
                <Button
                  size='lg'
                  className='goback-button btn-info'
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
              </div>
            </div>
            <div className='input-area-enddate'>
              <label className='label-publish'>Current Course End Date:
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-enddate">
                      Current Course End Date for reference
                    </Tooltip>}
                >
                  <i class="fa fa-info-circle" style={{ fontSize: '18px' }}></i>
                </OverlayTrigger>
              </label>
              <input
                type="date"
                className="enddate-form"
                value={formatDate(courseData?.closingDate)}
                disabled
              />
            </div>
          </div>
        </Col>
        <Col md={7}>
          <DataTable
            columns={durationColumns}
            data={courseDurationData}
            pagination
          />
        </Col>
      </Row>
    </div>
  )
}

export default UpdateCourseDuration