import React, { useState, useEffect } from "react";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import Sidebar from "../shared/Sidebar";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Card,
  Table,
} from "react-bootstrap";
import services from "../../services/service.js";
import moment from "moment";
import adminServices from "../../services/adminServices";
import UserService from "../../services/UserService";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import swal from "sweetalert";
import axios from 'axios';

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  //   },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "17px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
  cells: {
    style: {
      fontSize: "15px",
      paddingLeft: "0 8px",
      marginLeft: "10px",
    },
  },
};

function PaymentEnquiryfromGateway() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);

  const [courseDetailsShow, setCourseDetailsShow] = useState(false);
  const [getCoursePaymentDetails, setCoursePaymentDetails] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [getFormattedDate, setFormattedDate] = useState([]);

  useEffect(() => {
    getAllCourseList();
  }, []);

  useEffect(() => {
    //console.log("paymentDetails12345", paymentDetails);
  }, [paymentDetails]);

  //   useEffect(() => {
  //     setPaymentDetails([]);
  //   }, [getCoursePaymentDetails]);

  const getAllCourseList = () => {
    services
      .getAllCourses()
      .then((resp) => {
        //setLearnerList(resp.data);

        setCourseList(resp.data);
      })
      .catch((err) => {});
  };

  const [getLearnerData, setlearnerData] = useState([]);
  const [getUserName, setUserName] = useState();

  useEffect(() => {
    LearnersDatas();
  }, []);

  const LearnersDatas = () => {
    adminServices
      .getAllLearners()
      .then((resp) => {
        setlearnerData(resp.data);
      })
      .catch((err) => {});
  };

  const [getCourseIdName, setCourseIdName] = useState({
    id: "",
  });

  const [coursePayment, setCoursePayment] = useState({
    fromDate: "",
    toDate: "",
  });

  const dateHandler = (e) => {
    setCoursePayment({
      ...coursePayment,
      [e.target.name]: e.target.value,
    });
  };

  const onClickSubmitCourseandUserId = async (userId, courseId) => {
    setPaymentDetails([]);
    setCourseDetailsShow(true);
    setSubmitLoading({ isLoading: true });
    services
      .getPaymentDetailsbyuserIdandCourseId(userId, courseId)
      .then((resp) => {
        const newPaymentEntry = [];
        //
        if (resp.data.length === 0) {
          setSubmitLoading({ isLoading: false });
        }
        setCoursePaymentDetails(resp.data);
        {
          resp.data.forEach(async (data1) => {
            var t = new Date(data1.transactionDate);
            var formatted = moment(t).format("lll");

            //setFormattedDate(prevState => { return [...prevState, formatted] })

            //console.log("formatted --> ", formatted);
            //setUserName(prevState => { return [...prevState, res.data.firstName] })
            newPaymentEntry.push({ ...data1, formattedDate: formatted });
            //setPaymentDetails(prevState => { return [...prevState, newPaymentEntry] })
            setPaymentDetails([...newPaymentEntry]);
            setSubmitLoading({ isLoading: false });
          });
        }
      })
      .catch((err) => {});
  };

  const [getPaymentModeldata, setPaymentModeldata] = useState({
    reference_no: "",
    order_no: "",
    order_currncy: "",
    order_amt: "",
    order_capt_amt: "",
    order_date_time: "",
    order_ship_name: "",
    order_ship_tel: "",
    order_status: "",
    order_card_name: "",
    order_bank_ref_no: "",
    order_bank_response: "",
    status: "",
    error_code: "",
  });

  const [detailModalOpen, setDetailModalOpen] = useState(false);

  const [infoLoading, setInfoLoading] = useState({
    isLoading: false,
  });

  const [getCurrentOrderId, setCurrentOrderId] = useState();

  const [vResponse, setVResponse] = useState(null);

  const handleDetailModalClose = () => {
    setDetailModalOpen(false);
  };

  const getDetailsfromGateway = (orderId) => {
    setCurrentOrderId(orderId);
    setInfoLoading({ isLoading: true });
    // services
    //   .getPaymentDetailsbyGateway(orderId)
    //   .then((res) => {
    //     // console.log(res.data);
    //     setPaymentModeldata({
    //       ...getPaymentModeldata,
    //       reference_no: res.data.reference_no,
    //       order_no: res.data.order_no,
    //       order_currncy: res.data.order_currncy,
    //       order_amt: res.data.order_amt,
    //       order_capt_amt: res.data.order_capt_amt,
    //       order_date_time: res.data.order_date_time,
    //       order_ship_name: res.data.order_ship_name,
    //       order_ship_tel: res.data.order_ship_tel,
    //       order_status: res.data.order_status,
    //       order_card_name: res.data.order_card_name,
    //       order_bank_ref_no: res.data.order_bank_ref_no,
    //       order_bank_response: res.data.order_bank_response,
    //       status: res.data.status,
    //       error_code: res.data.error_code,
    //     });
    //     setInfoLoading({ isLoading: false });
    //     setDetailModalOpen(true);

    //   })
    //   .catch((err) => {
    //     setInfoLoading({ isLoading: false });
    //     console.log(err.data);
    //     // swal(`Information`, `Does not have any data regarding this payment`, "info")
    //   });

    services
      .PaymentDetailsEncrypReqString(orderId)
      .then(async (res) => {
        //console.log(res.data);

        const wsURL = "https://api.ccavenue.com/apis/servlet/DoWebTrans";
        // const wsURL = "https://cors-anywhere.herokuapp.com/https://api.ccavenue.com/apis/servlet/DoWebTrans";

        const response = await axios.post(wsURL, res.data);
        setVResponse(response.data);
        if(response.data != null){

            services.getPaymentDetailsbyGateway(response.data)
            .then(resp =>{
        // console.log(res.data);
        setPaymentModeldata({
          ...getPaymentModeldata,
          reference_no: res.data.reference_no,
          order_no: res.data.order_no,
          order_currncy: res.data.order_currncy,
          order_amt: res.data.order_amt,
          order_capt_amt: res.data.order_capt_amt,
          order_date_time: res.data.order_date_time,
          order_ship_name: res.data.order_ship_name,
          order_ship_tel: res.data.order_ship_tel,
          order_status: res.data.order_status,
          order_card_name: res.data.order_card_name,
          order_bank_ref_no: res.data.order_bank_ref_no,
          order_bank_response: res.data.order_bank_response,
          status: res.data.status,
          error_code: res.data.error_code,
        });
        setInfoLoading({ isLoading: false });
        setDetailModalOpen(true);

            })
            .catch(error =>{
                setInfoLoading({ isLoading: false });
                //console.log(error.data);
            })

        }
       
      })
      .catch((err) => {
        setInfoLoading({ isLoading: false });
        //console.log(err.data);
        // swal(`Information`, `Does not have any data regarding this payment`, "info")
      });
  };

  //console.log("getPaymentModeldata ----> ", getPaymentModeldata);

  const activityLogColumns = [
    // {
    //   name: "S.No",
    //   selector: (row, index) => index + 1,
    //   width: "150px",
    //   sortable: true,
    // },
    {
      name: "Order Id",
      selector: (row) => row.orderId,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "course Id",
      selector: (row) => row.courseId,
      //selector: row => moment.unix(row.userId).utc(),
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => row.fees,
      width: "200px",
      wrap: true,
    },
    {
      name: "Transaction Date",
      selector: (row) => row.formattedDate,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.approvalStatus,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <Button
            onClick={() => {
              getDetailsfromGateway(row.orderId);
            }}
            className="btn btn-info pull-left m-2 w-10"
          >
            {infoLoading.isLoading ? (
              getCurrentOrderId === row.orderId ? (
                <>{t("loading")}</>
              ) : (
                <>
                  <i class="fa fa-info-circle"></i> Check Details from Bank
                </>
              )
            ) : (
              <>
                <i class="fa fa-info-circle"></i> Check Details from Bank
              </>
            )}
          </Button>
        </>
      ),
      sortable: true,
      width: "250px",
      wrap: true,
    },
  ];

  const [submitLoading, setSubmitLoading] = useState({
    isLoading: false,
  });

  const [updateLoading, setUpdateLoading] = useState({
    isLoading: false,
  });

  const updatePaymentDetailsinDatabase = () => {
    setUpdateLoading({ isLoading: true });
    //console.log("vResponse -------> ", vResponse);

    services.updatePaymentDetailsGetbyGateway(vResponse)
    .then((res) =>{
        // console.log(res.data);
        if(res.data == "success"){
            swal(`${t('success')}`, `Payment Details successfully updated.`, 'success');
        }
        else  if(res.data == "success-n-course-assigned"){
            swal(`${t('success')}`, `Payment Details successfully updated and course is assigned to user`, 'success');
        }
        else{
            swal(`${t('error')}`, `Error occur while updating payment details`, 'error');
        }
        onClickSubmitCourseandUserId(getUserName, getCourseIdName.id);
        setUpdateLoading({ isLoading: false });
    })
    .catch(err =>{
        // console.log(err.data);
        swal(`${t('error')}`, `Error occur while updating payment details`, 'error');
        onClickSubmitCourseandUserId(getUserName, getCourseIdName.id);
        setUpdateLoading({ isLoading: false });
    })

  };

  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div>
              <div className="page-header">
                <h3 className="page-title">Payment Enquiry and Update</h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="!#" onClick={(event) => event.preventDefault()}>
                        {t("payment_details")}
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Payment Enquiry and Update
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="col-lg-12 grid-margin stretch-card">
                <div class="card" style={{ padding: "10px" }}>
                  <Col lg="12">
                    <Row>
                      <div class="col-8 col-md-4">
                        <Form.Group>
                          <label>{t("select_the_course")}</label>
                          <Form.Control
                            onChange={(e) =>
                              setCourseIdName({
                                ...getCourseIdName,
                                id: e.target.value,
                              })
                            }
                            as="select"
                            placeholder="Select Course"
                          >
                            <option selected>{t("choose")}</option>
                            {courseList.map((course, index) => {
                              return (
                                <option value={course.courseId}>
                                  {course.courseName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div class="col-8 col-md-4">
                        <Form.Group>
                          <label>Select User</label>
                          <Form.Control
                            onChange={(e) => setUserName(e.target.value)}
                            as="select"
                            placeholder="Select User"
                          >
                            <option selected>{t("choose")}</option>
                            {getLearnerData.map((learner, index) => {
                              return (
                                <option value={learner.learnerUsername}>
                                  {learner.firstName} {learner.lastName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div
                        className="col-8 col-md-4"
                        style={{ marginTop: "25px" }}
                      >
                        <Button
                          disabled={submitLoading.isLoading ? "true" : ""}
                          style={{ background: "green", border: "0px" }}
                          onClick={() => {
                            onClickSubmitCourseandUserId(
                              getUserName,
                              getCourseIdName.id
                            );
                          }}
                        >
                          {submitLoading.isLoading ? (
                            <> {t("loading")}</>
                          ) : (
                            <>{t("submit")}</>
                          )}
                        </Button>
                      </div>
                    </Row>
                    <br></br>
                    <br></br>
                    <Row>
                      <Card style={{ width: "100%" }}>
                        <br></br>
                        <h4 style={{ textAlign: "center" , fontWeight:"bold"}}>
                          List of Payment Attempts
                        </h4>
                        <br></br>

                        <br></br>
                        <DataTable
                          columns={activityLogColumns}
                          //title="Access Log"
                          data={paymentDetails}
                          defaultSortField="Order Id"
                          defaultSortAsc={true}
                          striped
                          pagination
                          highlightOnHover
                          customStyles={customStyles}
                          // subHeader
                          // subHeaderComponent={subHeaderComponent}
                        />
                      </Card>
                    </Row>
                  </Col>
                </div>
              </div>
            </div>
            <SettingsPanel />
          </div>
          <Footer />
        </div>
      </div>
      <Modal
        centered
        show={detailModalOpen}
        onHide={handleDetailModalClose}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details from Bank</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getPaymentModeldata.order_no != "" && (
            <div>
              <Table striped bordered hover responsive>
                <tbody>
                  <tr>
                    <td>User Name</td>
                    <td>{getPaymentModeldata.order_ship_name}</td>
                  </tr>
                  <tr>
                    <td>User Mobile No. (Provided)</td>
                    <td>{getPaymentModeldata.order_ship_tel}</td>
                  </tr>
                  <tr>
                    <td>Amount Paid</td>
                    <td>{getPaymentModeldata.order_capt_amt}</td>
                  </tr>
                  <tr>
                    <td>Course Fees</td>
                    <td>{getPaymentModeldata.order_amt}</td>
                  </tr>
                  <tr>
                    <td>Transaction Date</td>
                    <td>{getPaymentModeldata.order_date_time}</td>
                  </tr>
                  <tr>
                    <td>Bank Reference No.</td>
                    <td>{getPaymentModeldata.order_bank_ref_no}</td>
                  </tr>
                  <tr>
                    <td>Card Used</td>
                    <td>{getPaymentModeldata.order_card_name}</td>
                  </tr>
                  <tr>
                    <td>Payment Currency</td>
                    <td>{getPaymentModeldata.order_currncy}</td>
                  </tr>
                  <tr>
                    <td>Payment Status</td>
                    <td>{getPaymentModeldata.order_status}</td>
                  </tr>
                  <tr>
                    <td>Transaction Id</td>
                    <td>{getPaymentModeldata.reference_no}</td>
                  </tr>
                  <tr>
                    <td>Bank Status</td>
                    <td>{getPaymentModeldata.status}</td>
                  </tr>
                  <tr>
                    <td>Payment Bank Response</td>
                    <td>{getPaymentModeldata.order_bank_response}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={updateLoading.isLoading ? "true" : ""}
              style={{ background: "green", border: "0px" }}
              onClick={() => {
                updatePaymentDetailsinDatabase();
              }}
            >
              {updateLoading.isLoading ? (
                <> {t("loading")}</>
              ) : (
                <>Update Detail on Table</>
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentEnquiryfromGateway;
