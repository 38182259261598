import React from "react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import Sidebar from "../shared/Sidebar";
import UserService from "../../services/UserService";
import { Button, Card, Col, Row, Modal, Form, Table } from "react-bootstrap";
import ReactSelect from "react-select";
import StickyMenu from "../../components/common/StickyMenu";
import Footer from "../shared/Footer";
import Navbar from "../shared/Navbar";
import SettingsPanel from "../shared/SettingsPanel";
import DataTable from "react-data-table-component";
import { downloadExcel, DownloadTableExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import service from "../../services/service";

const customStyles = {
  title: {
    style: {
      fontColor: "red",
      fontWeight: "900",
    },
  },
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "500",
      paddingLeft: "0 8px",
      marginLeft: "10px",
      // justifyContent: 'center',
    },
  },
  cells: {
    style: {
      fontSize: "14px",
      // justifyContent: 'center',
      // paddingLeft: '0 8px',
      // marginLeft: '10px'
    },
  },
};

const customStyles2 = {
    title: {
      style: {
        fontColor: "red",
        fontWeight: "900",
      },
    },
    headCells: {
      style: {
        fontSize: "19px",
        fontWeight: "500",
        paddingLeft: "0 8px",
        marginLeft: "10px",
        
      },
    },
    cells: {
      style: {
        fontSize: "17px",
        background:"#ccf2ff",
        fontWeight:"bold",
        // paddingLeft: '0 8px',
        // marginLeft: '10px'
      },
    },
  };

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },

  //{
  //     code: 'hi',
  //     name: 'Hindi',
  //     country_code: 'in'
  // },
  // {
  //     code: 'te',
  //     name: 'Telugu',
  //     country_code: 'in'
  // },
  // {
  //     code: 'pu',
  //     name: 'Punjabi',
  //     country_code: 'in'
  // },
  // {
  //     code : 'mr',
  //     name : 'Marathi',
  //     country_code : 'in'
  // }
];

export default function RegisteredLearnerStatus() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  useEffect(() => {
    UserService.generateToken();
  }, []);


  const [getRegistrationData, setRegistrationData] = useState([]);
  const [getRegistrationDatawithdate, setRegistrationDatawithdate] = useState([]);
  


 
//   console.log("getRegistrationData ---------> ",getRegistrationData);
//   console.log("getRegistrationData[0] ---------> ",getRegistrationData[0]);

  useEffect(() => {

      service.LearnersCountForStatus()
      .then((res) =>{
        //console.log(res.data);
        setRegistrationData(res.data);
      })
      .catch((err) =>{

      })
  }, []);

  const LearnersCountForStatuswithDates =(fromdate, todate) =>{

    service.LearnersCountForStatuswithDates(fromdate, todate)
    .then((res) =>{
        //console.log(res.data);
        setRegistrationDatawithdate(res.data);
        setSubmitLoading({ isLoading: false });
      })
      .catch((err) =>{
        setSubmitLoading({ isLoading: false });

      })

  }

  const columns2 = [
    {
      name: "Total Registered Users",
      selector: (row) => `${row.totalCount}`,
      wrap: true,
    },
    {
        name: "Male Registered Users",
        selector: (row) => `${row.malecount}`,
        wrap: true,
      },
      {
        name: "Female Registered Users",
        selector: (row) => `${row.femalecount}`,
        wrap: true,
      },
    {
      name: "Approved Users",
      selector: (row) => `${row.learnerApprovedCount}`,
      wrap: true,
    },
    {
      name: "Rejected Users",
      selector: (row) => `${row.learnerRejectedCount}`,
      wrap: true,
    },
    {
        name: "Users Request Pending",
        selector: (row) => `${row.activatedlearnerCount}`,
        wrap: true,
      },
      {
        name: "Registered (Email verification pending)",
        selector: (row) => `${row.onlyRegisteredlearnerCount}`,
        wrap: true,
      },

  ];

  const tableExportExcel = () => {
    var checkData = [];
    const header = ["Total Registered Users", "Male Registered Users", "Female Registered Users", "Approved Users", "Rejected Users", "Users Request Pending", "Registered (Email verification pending)"]
    if (getRegistrationData !== undefined) {
        getRegistrationData.map((data) => {
            const totalCount = `${data.totalCount}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;
            const learnerApprovedCount = `${data.learnerApprovedCount}`;
            const learnerRejectedCount = `${data.learnerRejectedCount}`;
            const activatedlearnerCount = `${data.activatedlearnerCount}`;
            const onlyRegisteredlearnerCount = `${data.onlyRegisteredlearnerCount}`;
           
            const instData = [totalCount, malecount, femalecount, learnerApprovedCount, learnerRejectedCount, activatedlearnerCount, onlyRegisteredlearnerCount]
            checkData.push(instData);
        })
    }
    downloadExcel({
        fileName: "Registration Status",
        sheet: "Registration Status",
        tablePayload: {
            header,
            body: checkData,
        },
    })
  };

  const tableExportPDF = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(13);
    const title = "Registration Report";
    const headers = [["Total Registered Users", "Male Registered Users", "Female Registered Users", "Approved Users", "Rejected Users", "Users Request Pending","Registered (Email verification pending)"]];
    var checkData = [];
    if (getRegistrationData !== undefined) {
        getRegistrationData.map((data) => {
            const totalCount = `${data.totalCount}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;
            const learnerApprovedCount = `${data.learnerApprovedCount}`;
            const learnerRejectedCount = `${data.learnerRejectedCount}`;
            const activatedlearnerCount = `${data.activatedlearnerCount}`;
            const onlyRegisteredlearnerCount = `${data.onlyRegisteredlearnerCount}`;

            const instData = [totalCount, malecount, femalecount, learnerApprovedCount, learnerRejectedCount, activatedlearnerCount, onlyRegisteredlearnerCount]
            checkData.push(instData);
        })
    }
    let content = {
        startY: 50,
        head: headers,
        body: checkData
    };
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("RegistrationStatus.pdf")
  };

  const tableExportExcelwithdate = () => {
    var checkData = [];
    const header = ["Total Registered Users", "Male Registered Users", "Female Registered Users", "Approved Users", "Rejected Users", "Users Request Pending", "Registered (Email verification pending)"]
    if (getRegistrationDatawithdate !== undefined) {
        getRegistrationDatawithdate.map((data) => {
            const totalCount = `${data.totalCount}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;
            const learnerApprovedCount = `${data.learnerApprovedCount}`;
            const learnerRejectedCount = `${data.learnerRejectedCount}`;
            const activatedlearnerCount = `${data.activatedlearnerCount}`;
            const onlyRegisteredlearnerCount = `${data.onlyRegisteredlearnerCount}`;
           
            const instData = [totalCount, malecount, femalecount, learnerApprovedCount, learnerRejectedCount, activatedlearnerCount, onlyRegisteredlearnerCount]
            checkData.push(instData);
        })
    }
    downloadExcel({
        fileName: "Registration Status",
        sheet: "Registration Status",
        tablePayload: {
            header,
            body: checkData,
        },
    })
  };

  const tableExportPDFwithdate = () => {
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape"
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(13);
    const title = "Registration Report";
    const headers = [["Total Registered Users", "Male Registered Users", "Female Registered Users", "Approved Users", "Rejected Users", "Users Request Pending", "Registered (Email verification pending)"]];
    var checkData = [];
    if (getRegistrationDatawithdate !== undefined) {
        getRegistrationDatawithdate.map((data) => {
            const totalCount = `${data.totalCount}`;
            const malecount = `${data.malecount}`;
            const femalecount = `${data.femalecount}`;
            const learnerApprovedCount = `${data.learnerApprovedCount}`;
            const learnerRejectedCount = `${data.learnerRejectedCount}`;
            const activatedlearnerCount = `${data.activatedlearnerCount}`;
            const onlyRegisteredlearnerCount = `${data.onlyRegisteredlearnerCount}`;

            const instData = [totalCount, malecount, femalecount, learnerApprovedCount, learnerRejectedCount, activatedlearnerCount, onlyRegisteredlearnerCount]
            checkData.push(instData);
        })
    }
    let content = {
        startY: 50,
        head: headers,
        body: checkData
    };
    doc.text(title, 40, 40);
    doc.autoTable(content);
    doc.save("RegistrationStatus.pdf")
  };


  const [submitLoading, setSubmitLoading] = useState({
    isLoading: false
})

const dateHandler = (e) => {
  setActivityLogDateRange({
      ...activityLogDateRange,
      [e.target.name]: e.target.value
  })
}

const [activityLogDateRangeError, setActivityLogDateRangeError] = useState({
  fromDateError: '',
  toDateError: '',
});

const [activityLogDateRange, setActivityLogDateRange] = useState({
  fromDate: '',
  toDate: ''
})

const activityLogDateRangeValidate = () => {
    let fromDateErr = ''
    let toDateErr = ''
  
    if (!activityLogDateRange.fromDate) {
        fromDateErr = t('Date_required')
    }
    if (!activityLogDateRange.toDate) {
        toDateErr = t('Date_required')
    }
  
    if (activityLogDateRange.fromDate && activityLogDateRange.toDate) {
        const from = new Date(activityLogDateRange.fromDate).getTime();
        const to = new Date(activityLogDateRange.toDate).getTime();
        if (from > to) {
            fromDateErr = t('should_be_less_than_to_date')
        }
    }
    if (fromDateErr || toDateErr) {
        setActivityLogDateRangeError({
            ...activityLogDateRangeError,
            fromDateError: fromDateErr,
            toDateError: toDateErr,
        })
        return false
    }
    return true
  }

  

  
const onClickSubmitActivityLog = () => {
    // //console.log(UserService.getUserid())
    ////console.log(activityLogDateRange);
    const condition = activityLogDateRangeValidate();
    if (condition) {
        setActivityLogDateRangeError({
            ...activityLogDateRangeError,
            fromDateError: '',
            toDateError: '',
        })
        setSubmitLoading({ isLoading: true });
        LearnersCountForStatuswithDates(activityLogDateRange.fromDate, activityLogDateRange.toDate);
    }
  }
  
  


  return (
    <div className="container-scroller">
      <Navbar />
      <StickyMenu />
      <div className="container-fluid page-body-wrapper">
        <Sidebar />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">Registration Report</h3>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="!#" onClick={(event) => event.preventDefault()}>
                      Reports
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Registration Report
                  </li>
                </ol>
              </nav>
            </div>
            <div class="card" style={{ padding: "40px" }}>
              <div style={{margin:"10px", backgroundColor:"#efefef", padding:"20px"}}>
              <Row>
            <Col lg="12">
                  <div>
                    <h4 style={{ textAlign: "center" , fontWeight:"bold"}}>
                    Registration Report
                    </h4>
                  </div>
                  <Col lg={{ span: 3, offset: 9 }}>
                  <div className="d-flex px-2">
                    <Button
                      onClick={tableExportExcel}
                      style={{
                        marginRight: "5px",
                        background: "#f0ad4e",
                        border: "0px",
                      }}
                    >
                      {" "}
                      {t("export_to_excel")}{" "}
                    </Button>
                    <Button
                      onClick={tableExportPDF}
                      style={{ background: "#f0ad4e", border: "0px" }}
                    >
                      {" "}
                      {t("export_to_pdf")}{" "}
                    </Button>
                  </div>
                </Col>
                  </Col>
              </Row>
              <br></br>
              <Row style={{margin:"10px", backgroundColor:"#ffffff", padding:"20px"}}>
              <Col lg={{span: 12, offset: 0}}>
                <div style={{ overflowX: "auto", marginBottom:"10px"}}>
                  <DataTable
                    columns={columns2}
                    data={getRegistrationData}
                    // striped
                    highlightOnHover
                    customStyles={customStyles2}
                  />
                </div>
                </Col>
              </Row>
              <br></br>
              <h4 style={{ textAlign: "center" , fontWeight:"bold"}}>
                    Registration Report with Date Ranges
                    </h4>
              <Row style={{margin:"10px", backgroundColor:"#ffffff", padding:"20px"}}>
                <Col lg={{span: 4, offset: 0}} style={{backgroundColor:"#e3ffed", marginRight:"20px", padding:"20px"}}>
                    <div>
                      <label class="control-label" for="name">
                        {t("from_date")} :{" "}
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        step="1"
                        id="publishDate"
                        name="fromDate"
                        value={activityLogDateRange.fromDate}
                        onChange={dateHandler}
                      />
                      {activityLogDateRangeError.fromDateError ? (
                        <div className="alert alert-danger mt-2">
                          {activityLogDateRangeError.fromDateError}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <br/>
                    <div >
                      <label class="control-label" for="name">
                        {t("to_date")} :{" "}
                      </label>
                      <input
                        type="date"
                        class="form-control"
                        title={t("select_course_publish_date")}
                        step="1"
                        id="enrollSdate"
                        name="toDate"
                        value={activityLogDateRange.toDate}
                        onChange={dateHandler}
                      />
                      {activityLogDateRangeError.toDateError ? (
                        <div className="alert alert-danger mt-2">
                          {activityLogDateRangeError.toDateError}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div style={{ marginTop: "25px" }}>
                      <Button
                        onClick={onClickSubmitActivityLog}
                        disabled={submitLoading.isLoading ? "true" : ""}
                        style={{ background: "green", border: "0px" }}
                      >
                        {" "}
                        {submitLoading.isLoading ? (
                          <> {t("loading")}</>
                        ) : (
                          <>{t("submit")}</>
                        )}{" "}
                      </Button>
                    </div>
                  
                  <br/>
                  {(getRegistrationDatawithdate[0] != undefined) && (<Col style={{margin:"10px"}}>
                    <div className="d-flex px-2">
                      <Button
                        onClick={tableExportExcelwithdate}
                        style={{
                          marginRight: "5px",
                          background: "#f0ad4e",
                          border: "0px",
                        }}
                      >
                        {" "}
                        {t("export_to_excel")}{" "}
                      </Button>
                      <Button
                        onClick={tableExportPDFwithdate}
                        style={{ background: "#f0ad4e", border: "0px" }}
                      >
                        {" "}
                        {t("export_to_pdf")}{" "}
                      </Button>
                    </div>
                  </Col>)}
                </Col>
              <Col lg={{span: 7, offset: 0}}>
                {(getRegistrationDatawithdate[0] != undefined) ? (<Table className="striped">
                    <tr >
                        <td><h5>Total Registered Users</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].totalCount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Male Registered Users</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].malecount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Female Registered Users</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].femalecount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Approved Users</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].learnerApprovedCount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Rejected Users</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].learnerRejectedCount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Users Request Pending</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].activatedlearnerCount}</h5></td>
                    </tr>
                    <tr >
                        <td><h5>Registered (Email verification pending)</h5></td>
                        <td><h5>{getRegistrationDatawithdate[0].onlyRegisteredlearnerCount}</h5></td>
                    </tr>
                </Table>):<div style={{textAlign:"center", margin:"20px"}}>
                    <h6>
                        Kindly select dates range to check Registration Report.
                    </h6>
                    </div>}
                </Col>
              </Row>
              
             
              </div> 
            </div>
          </div>
          <SettingsPanel />
          <Footer />
        </div>
      </div>
    </div>
  );
}
